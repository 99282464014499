import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from '@capgo/ngx-intl-tel-input';
import { ForgetPasswordService } from './forget-password.service';
import { TranslateService } from "@ngx-translate/core";
import { EventService, EventType } from "../utilities/event.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ForgottenPasswordResponse } from "./model/forgotten-password-response.model";
import { ForgottenPasswordOtpResponse } from "./model/forgotten-password-otp-response.model";
import { VerifyForgottenPasswordOtpResponse } from "./model/verify-forgotten-password-otp-response.model";

@Component({
    selector: 'md-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.Mauritius
    ];
    forgotPasswordForm!: UntypedFormGroup;
    mobileOtp: string;
    verifyMobileOtpPopup = false;
    emailPara = true;
    mobileOpacity = false;
    customerId!: string;
    isOtpDisabled = false;

    @ViewChild('verifyOtpModal') verifyOtpModal: TemplateRef<never>;

    constructor(private fpService: ForgetPasswordService,
                private router: Router,
                private modal: NgbModal,
                private eventService: EventService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.forgotPasswordForm = new UntypedFormGroup({
            'email': new UntypedFormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
            'mobile': new UntypedFormControl('', [Validators.pattern("[0-9]{0-10}")])
        });
    }

    clearForm() {
        this.forgotPasswordForm.reset();
    }

    onSubmitForgotPassForm() {
        const emailDetail = this.forgotPasswordForm.controls['email'];
        const email = this.forgotPasswordForm.controls['email'].value; //either value or blank
        const mobileDetail = this.forgotPasswordForm.controls['mobile'];
        const mobile = this.forgotPasswordForm.controls['mobile'].value?.e164Number; //either value or undefined

        if (this.emailPara) {
            emailDetail.status
            if (emailDetail?.status === 'INVALID') {
                this.eventService.emitEvent(EventType.MODAL_ERROR, this.translate.instant(
                  'Please enter a valid email id'));
            } else if (emailDetail?.status === 'VALID') {
                this.fpService.forgotPasswordByEmail(email).subscribe({
                    next: (response: ForgottenPasswordResponse) => {
                        if (response.response === 'success') {
                            this.eventService.emitEvent(EventType.LOGIN);
                            this.eventService.emitEvent(EventType.MODAL_INFO, this.translate.instant(
                              'You will receive an email with a link to reset your password'));
                            window.sessionStorage.setItem('changePasswordAccessToken', response.token);
                        } else if (response.response === 'error') {
                            this.eventService.emitEvent(EventType.MODAL_ERROR, this.translate.instant(
                              'This email id is not registered with us'));
                        }
                    }, error: (error) => {
                        console.log(error);
                    }
                });
            }
        } else if (this.mobileOpacity) {
            if (!mobileDetail || !mobileDetail.value || mobileDetail.value.number.length < 6) {
                this.eventService.emitEvent(EventType.MODAL_ERROR, this.translate.instant(
                  'Mobile number should be minimum six digits'));
            } else {
                const encodedMobile = btoa(mobile);
                this.fpService.sendForgotPasswordOtp(encodedMobile).subscribe({
                    next: (response: ForgottenPasswordOtpResponse) => {
                        if (response.status === 'error') {
                            this.eventService.emitEvent(EventType.MODAL_ERROR, response.message)
                        } else if (response.status === 'success') {
                            this.customerId = response.customerId;
                            this.verifyMobileOtpPopup = true;
                        }
                    }, error: (error) => {
                        console.log(error);
                    }
                });
            }
        } else {
            this.eventService.emitEvent(EventType.MODAL_ERROR, this.translate.instant(
              'Please provide a valid email address'));
        }
    }

    changeNumWithStars(num: string): string {
        if (num) {
            const startNumExpectLast4digits = num.slice(0, -4);
            const last4digits = num.slice(-4);
            const last2digits = last4digits.slice(-2);
            const first2digits = last4digits.slice(0, -2);
            const replacedDigitsToStars = first2digits.replace(/./gi, '*');
            return startNumExpectLast4digits + replacedDigitsToStars + last2digits;
        }
        return '';
    }

    resendMobileOtp() {
        this.onSubmitForgotPassForm();
    }

    mobileOtpInputChange(inputValue: string) {
        this.mobileOtp = inputValue;
        if (inputValue.length === 4) {
            this.isOtpDisabled = true;
            this.verifyMobileOtp(true);
        } else {
            this.isOtpDisabled = false;
        }
    }

    verifyMobileOtp(isSilent = false) {
        if (this.mobileOtp.length < 4) {
            return;
        }
        this.fpService.verifyForgotPasswordOtp(this.mobileOtp, this.customerId).subscribe({
            next: (response: VerifyForgottenPasswordOtpResponse) => {
                if (response.status === 'error' && !isSilent) {
                    this.eventService.emitEvent(EventType.MODAL_ERROR, response.message);
                } else if (response.status === 'success') {
                    window.sessionStorage.setItem('changePasswordAccessToken', response.token)
                    this.eventService.emitEvent(EventType.MODAL_INFO, response.message);
                    this.redirectToCreateNewPasswordPage();
                }
            }, error: (error) => {
                console.debug(error);
            }
        })
    }

    redirectToCreateNewPasswordPage() {
        this.router.navigate(['customer/account/createPassword']);
    }
}
