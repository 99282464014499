import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ProductDetails } from 'src/app/product-detail/model/product-detail.model';
import { DatedActivityBookingService } from './dated-activity-booking.service';
import {
    ProductPricingByDate,
    transformProductPricingByDateDtoToPricingByDate,
} from './dated-activity-booking.model';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { transformNgbDateToDayJs } from 'src/app/controls/calendar/calendar.utils';
import { BookingConfiguredData } from '../booking-config.model';
import * as _ from 'lodash';

@Component({
    selector: 'md-dated-activity-booking',
    templateUrl: './dated-activity-booking.component.html',
    styleUrls: ['./dated-activity-booking.component.scss'],
})
export class DatedActivityBookingComponent implements OnInit {
    @Input() set productDetails(value: ProductDetails) {
        if (!value) return;
        this.internalProductDetails = value;
        this.fullProductDetails = { ...value };
        this.service.getNewCalendarDates(value.id).subscribe((data) => {
            this.pricingByDate =
                transformProductPricingByDateDtoToPricingByDate(data);

            this.additionalCalendarDayInfo.clear();
            this.pricingByDate.forEach((pricingByDate) =>
                this.additionalCalendarDayInfo.set(pricingByDate.dateAsString, {
                    date: pricingByDate.date,
                    tooltipContent: `${this.currencySymbol} ${pricingByDate.options[0].price}`,
                    minLengthOfStay: 1,
                })
            );
        });
    }

    currencySymbol: 'Rs.' | 'EUR';
    internalProductDetails: ProductDetails;
    fullProductDetails: ProductDetails;
    pricingByDate: ProductPricingByDate[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedDate: NgbDate;
    formattedDate = '-';
    tempBookingData: BookingConfiguredData;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarDatedActivity')
    calendarDatedActivity: CalendarComponent;

    constructor(
        private service: DatedActivityBookingService,
        private currencyLanguageService: CurrencyLanguageService
    ) {}
    ngOnInit(): void {
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
    }

    onOpenCalendar(): void {
        // this.calendarDatedActivity.openCalendar();
    }

    onDateRangeSelected(dateRange: CalendarSelectedDates): void {
        this.selectedDate = dateRange.singleDate;
        this.formattedDate = transformNgbDateToDayJs(this.selectedDate).format(
            'DD MMM YYYY'
        );

        // get the options prices for the selected date from pricingByDate
        const selectedDatePricing = this.pricingByDate.find((pricing) =>
            this.selectedDate.equals(pricing.date)
        );
        this.internalProductDetails = _.cloneDeep(this.fullProductDetails);

        if (selectedDatePricing) {
            // replace the options prices in the internalProductDetails based on the date and on the optionId
            this.internalProductDetails.serviceOptionCategories.forEach(
                (serviceOption) => {
                    serviceOption.options.forEach((option) => {
                        const optionPricing = selectedDatePricing.options.find(
                            (pricing) => pricing.optionId === option.id
                        );
                        if (optionPricing) {
                            option.price = optionPricing.price;
                        }
                    });

                    serviceOption.options = serviceOption.options.filter(
                        (option) => {
                            return selectedDatePricing.options.some(
                                (pricing) => pricing.optionId === option.id
                            );
                        }
                    );
                }
            );

            // Remove serviceOptionCategories with no options
            this.internalProductDetails.serviceOptionCategories =
                this.internalProductDetails.serviceOptionCategories.filter(
                    (serviceOption) => serviceOption.options.length > 0
                );
        }

        this.calendarDatedActivity.closeCalendar();

        if (this.tempBookingData) {
            this.onBookingConfigured(this.tempBookingData);
        }
    }

    onBookingConfigured(data: BookingConfiguredData): void {
        if (!this.selectedDate) {
            this.tempBookingData = data;
            this.calendarDatedActivity.openCalendar();
            return;
        }

        if (this.selectedDate && data.productBookingData) {
            data.productBookingData.selectedDate = this.selectedDate;
            this.tempBookingData = undefined;
            this.bookingConfigured.emit(data);
        }
    }
}
