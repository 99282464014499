<div class="main-div register-component-parent-div">
    <div class="register-div">
        <div class="card register-card">
            <div class="card-body">
                <form [formGroup]="registerForm" class="register-form">
                    <div class="form-group">
                        <div class="form-group">
                            <label class="form-label-signup" for="firstName"
                                   [ngClass]="firstNameClass">{{'First Name' | translate}} </label>
                            <input type="text"
                                   (keyup)="onFirstNameChange()"
                                   id="firstName"
                                   #firstName
                                   [ngClass]="['form-control', 'from-input-signup', firstNameErrorClass]"
                                   (focusin)="firstNameErrorClass = ''"
                                   (focusout)="validateFirstName()"
                                   [placeholder]="'First Name' | translate"
                                   formControlName="firstName"/>
                        </div>

                        <div class="form-group">
                            <label class="form-label-signup" for="lastName"
                                   [ngClass]="lastNameClass">{{'Last Name' | translate}} </label>
                            <input type="text"
                                   (keyup)="onLastNameChange()"
                                   id="lastName"
                                   #lastName
                                   [ngClass]="['form-control', 'from-input-signup', lastNameErrorClass]"
                                   (focusin)="lastNameErrorClass = ''"
                                   (focusout)="validateLastName()"
                                   [placeholder]="'Last Name' | translate"
                                   formControlName="lastName"/>

                        </div>

                        <div class="form-group ">
                            <label class="form-label-signup" for="email"
                                   [ngClass]="emailClass">{{'Email Address' | translate}}</label>
                            <input type="email"
                                   id="email"
                                   #email
                                   [ngClass]="['form-control', 'from-input-signup', emailErrorClass]"
                                   (focusin)="emailErrorClass = ''"
                                   (focusout)="validateEmail()"
                                   (keyup)="onEmailChange()"
                                   [placeholder]="'Email Address' | translate" formControlName="email"/>

                        </div>

                        <div class="card form form-group">
                            <label class="form-label-signup custom-user-profile-label-2" for="country"
                                   [ngClass]="'has-input'">
                                {{"Country" | translate}}</label>
                            <select class="newStyleInput custom-user-profile-select-6"
                                    formControlName="country" (change)="onChangeCountry('1')">
                                <option value="">{{"Select" | translate}}</option>
                                <option *ngFor="let country of countries"
                                        [value]="country.code">{{country.name}}
                                </option>
                            </select>
                        </div>

                        <div class="card form form-group" *ngIf="shouldDesiplayOtherCountriesSelect">
                            <label class="form-label-signup custom-user-profile-label-2"
                                   [ngClass]="'has-input'">
                                {{"Select" | translate}}</label>
                            <select class="newStyleInput custom-user-profile-select-6"
                                    formControlName="country" (change)="onChangeCountry('2')">
                                <option value="">{{"Select" | translate}}</option>
                                <option [value]="country.value"
                                        *ngFor="let country of CountryISO|keyvalue">{{country.key}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="form-label-signup" for="mobile"
                                   [ngClass]="mobileClass">{{'Mobile Number' | translate}}</label>

                            <div style="margin-bottom: 0.5rem;" class="signup_password_input mobile-number">

                                <ngx-intl-tel-input [ngClass]="['mobile-input', 'from-input-signup', mobileErrorClass]"
                                                    id="mobile"
                                                    #mobile
                                                    [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="false"
                                                    [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="selectedCountryCode"
                                                    [maxLength]="15"
                                                    [phoneValidation]="true"
                                                    [separateDialCode]="separateDialCode"
                                                    [numberFormat]="PhoneNumberFormat.National"
                                                    [customPlaceholder]="'Mobile Number' | translate"
                                                    formControlName="mobile"
                                                    (keyup)="onMobileChange()"
                                                    (focusin)="mobileErrorClass = ''"
                                                    (focusout)="validateMobile()"
                                                    (countryChange)="onPhoneCountryChange($event)">
                                </ngx-intl-tel-input>
                            </div>

                        </div>

                        <div class="form-group">
                            <label class="form-label-signup" for="password"
                                   [ngClass]="passwordClass">{{'Password' | translate}}</label>
                            <input [type]="passwordInputType"
                                   id="password"
                                   #password
                                   [ngClass]="['form-control', 'from-input-signup', passwordErrorClass]"
                                   (keyup)="onPasswordChange()"
                                   (focusin)="passwordErrorClass = ''"
                                   (focusout)="validatePassword()"
                                   [placeholder]="'Password' | translate"
                                   formControlName="password"/>
                            <div (click)="toggleShowPassword()"
                                 [ngClass]="['fa', passwordIconClass, 'showandhidepass']"></div>
                        </div>

                        <div class="term-content">{{'By clicking an option below, I agree to the' | translate}}
                            <a class="term-condition"
                               href="https://marideal.zendesk.com/hc/en-us/articles/360013372919"
                               target="_blank">{{'Terms of Use' | translate}}</a> {{'and have read the' | translate}}
                            <a class="term-condition"
                               href="https://marideal.zendesk.com/hc/en-us/articles/360013052960"
                               target="_blank">{{'Privacy Statement' | translate}}</a>.
                        </div>


                        <button class="btn btn-primary sign-in submit-button ripleEffect"
                                (click)="submitRegistrationForm()">
                            {{'Sign up with MariDeal' | translate}}
                        </button>

                        <ng-template #verifyOtpAccount let-modal>
                            <div class="modal-header">
                                <span class="popup-close-btn" (click)="modal.close('Save click')">×</span>
                                <img [src]="'assets/img/OTP-Login.png'" alt="otp-login-image">
                                <h4>{{'Please Enter the OTP to verify your account' | translate}}</h4>
                            </div>
                            <div class="modal-body">
                                <p class="mobile-text ">{{'OTP(one-time-password) has been sent to' | translate}}
                                    <span>{{otpMobileNumber}}</span>
                                </p>
                                <!-- Start OTP Form -->
                                <form class="email-login-form">

                                    <div class="mb-6 text-center">
                                        <ng-otp-input id="partitioned"
                                                      (onInputChange)="onOtpChange($event)"
                                                      allowNumbersOnly="true"
                                                      [config]="{length:4}"></ng-otp-input>
                                    </div>

                                    <div class="form-group">
                                        <div>{{"Didn't receive OTP?" | translate}}</div>
                                        <a (click)="resendOtp()">{{'Resend' | translate}}</a>
                                    </div>
                                    <div class="form-group">
                                        <button class="ripleEffect" type="submit">{{'Verify' | translate}}</button>
                                    </div>
                                </form>
                                <!-- End OTP Form -->
                            </div>
                        </ng-template>
                        <div class="col text-center" style="padding-bottom: 1.5rem;">
                            <a (click)="navigateToLogin()"
                               class="normal_font account-setting">
                                {{'Already have an account?' | translate}}
                            </a>
                        </div>

                        <md-app-social-login></md-app-social-login>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
