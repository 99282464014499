import { Component, OnInit, ViewChild } from '@angular/core';
import { EditService } from './edit.service';
import { CommonService } from 'src/app/shared/common.service';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../../../utilities/event.service';
import { getUser } from '../../../utilities/user.utils';
import { User } from '../../../model/user.model';
import { ProfileSection } from '../../profile-wrapper.component';
import { EditProfileUtilsService } from './edit-profile.utils.service';
import { isNullOrWhiteSpace } from 'src/app/utilities/utils';
import * as dayjs from 'dayjs';
import { SettingsService } from '../settings/settings.service';
import { ModalOffcanvasComponent } from 'src/app/controls/modal-offcanvas/modal-offcanvas.component';

declare var $;

@Component({
    selector: 'md-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
    localeTxt: any;
    loginStatus!: number;
    public loginResponse: User;
    public userLogin: boolean = false;
    customerId: string;
    mobile_verify: string;
    verify: boolean;
    email_verify: string;
    mailVerify: boolean;
    billing: any;
    isEditCustomerShowSkeleton: boolean = true;
    isWeddingElementEnabled: boolean = false;
    show_data: boolean;
    userFormGroup = new UntypedFormGroup({
        language: new UntypedFormControl('', []),
        firstname: new UntypedFormControl('', []),
        lastname: new UntypedFormControl('', []),
        email: new UntypedFormControl('', []),
        telephone: new UntypedFormControl('', []),
        dobmonth: new UntypedFormControl('', []),
        dobdate: new UntypedFormControl('', []),
        dobyear: new UntypedFormControl('', []),
        location: new UntypedFormControl('', []),
        married: new UntypedFormControl('', []),
        children: new UntypedFormControl('', []),
        street: new UntypedFormControl('', []),
        country: new UntypedFormControl('', []),
        gender: new UntypedFormControl('', []),
        wedmonth: new UntypedFormControl('', []),
        weddate: new UntypedFormControl('', []),
        wedyear: new UntypedFormControl('', []),
    });
    emailVerifyOtpForm = new UntypedFormGroup({
        emailVerifyOtp: new UntypedFormControl('', [Validators.maxLength(4)]),
    });
    mobileVerifyOtpForm = new UntypedFormGroup({
        mobileVerifyOtp: new UntypedFormControl('', [Validators.maxLength(4)]),
    });

    customerVerificationInfoResponse: any;
    preferred_holiday_values = [];
    location: any = [];
    isDobEditable: boolean = true;
    customerInfo: any;
    holidayLocation: any = [];
    prefferdLocation: any;
    selectedLang: string = '';
    verifyMobileOtpPopup: boolean = false;

    @ViewChild('otpModal') otpModal: ModalOffcanvasComponent;

    sectionBeingEdited:
        | ''
        | 'name'
        | 'email'
        | 'phone'
        | 'address'
        | 'dob'
        | 'gender' = '';

    constructor(
        private editService: EditService,
        public common: CommonService,
        private eventService: EventService,
        private translate: TranslateService,
        public editProfileUtilsService: EditProfileUtilsService,
        private settingsService: SettingsService
    ) {}

    ngOnInit(): void {
        this.checkLoginResponse();
        this.editProfileUtilsService.populateData();

        const userData = getUser();

        if (userData) {
            if (userData.preferredLanguage) {
                this.translate.setDefaultLang(userData.preferredLanguage);
                this.common.setCache(
                    this,
                    'language',
                    userData.preferredLanguage
                );
                this.selectedLang = userData.preferredLanguage;
            } else {
                this.translate.setDefaultLang(
                    this.common.getCache(this, 'language')
                        ? this.common.getCache(this, 'language').data
                        : 'en'
                );
                this.common.setCache(
                    this,
                    'language',
                    this.common.getCache(this, 'language')
                        ? this.common.getCache(this, 'language').data
                        : 'en'
                );
                this.selectedLang = this.common.getCache(this, 'language')
                    ? this.common.getCache(this, 'language').data
                    : 'en';
            }
        }

        // $('select.custom-user-profile-select-1 > option:nth-child(1)').click();
    }

    checkLoginResponse() {
        let userdata = getUser();
        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse?.id;
            this.userLogin = true;
            this.getCustomerInfo();
            this.getCustomerVerificationInfo();
        } else {
            this.eventService.emitEvent(EventType.LOGIN);
            return false;
        }
    }

    getCustomerInfo() {
        this.editService
            .getCustomerVerificationInfo(this.customerId)
            .subscribe((response: any) => {
                if (response.status == 'success') {
                    if (response.verify_mobile == true) {
                        this.mobile_verify = 'VERIFIED MOBILE NUMBER';
                        this.verify = true;
                    } else {
                        this.mobile_verify = 'VERIFY YOUR MOBILE NUMBER';
                        this.verify = false;
                    }
                    if (response.verify_email == true) {
                        this.email_verify = 'VERIFIED EMAIL ADDRESS';
                        this.mailVerify = true;
                    } else {
                        this.email_verify = 'VERIFY YOUR EMAIL ADDRESS';
                        this.mailVerify = false;
                    }
                    this.getData();
                }
            });
    }

    getData() {
        this.isEditCustomerShowSkeleton = true;
        this.editService.profileData(this.customerId).subscribe(
            (response: any) => {
                this.isEditCustomerShowSkeleton = false;
                if (response) {
                    if (!this.billing) {
                        this.show_data = true;
                        this.customerInfo = response.CustomerInfo;
                        this.location = response.location;
                        this.isEditCustomerShowSkeleton = false;
                        this.billing = response.BillingAddress;
                        this.holidayLocation =
                            response.BillingAddress.preferred_holiday_options;
                        this.prefferdLocation =
                            response.BillingAddress.preferred_holiday_values;

                        for (let item of this.holidayLocation) {
                            if (item.isSelected) {
                                this.preferred_holiday_values.push(item.value);
                            }
                        }

                        this.common.setCache(
                            this,
                            'billing_address',
                            JSON.stringify(this.billing)
                        );
                        const controls: any = this.userFormGroup.controls;
                        const keyArray = Object.keys(controls);
                        keyArray.forEach((name: any) => {
                            controls[name].setValue(this.billing[name]);
                        });
                        this.userFormGroup.controls['email'].setValue(
                            this.customerInfo.email
                        );
                        this.userFormGroup.controls['telephone'].setValue(
                            this.customerInfo.telephone
                        );
                        this.userFormGroup.controls['location'].setValue(
                            this.customerInfo.location
                        );
                        if (response.CustomerInfo.dob) {
                            const dateArray =
                                response.CustomerInfo.dob.split('-');
                            this.userFormGroup.controls['dobyear'].setValue(
                                dateArray[0]
                            );
                            this.userFormGroup.controls['dobdate'].setValue(
                                dateArray[2]
                            );
                            this.userFormGroup.controls['dobmonth'].setValue(
                                dateArray[1]
                            );
                            this.isDobEditable = response.CustomerInfo?.dob
                                ? false
                                : true;
                        }
                        const dateArray1 =
                            response.BillingAddress.wed_anniversary.split('/');
                        this.userFormGroup.controls['wedyear'].setValue(
                            dateArray1[2]
                        );
                        this.userFormGroup.controls['weddate'].setValue(
                            dateArray1[1]
                        );
                        this.userFormGroup.controls['wedmonth'].setValue(
                            dateArray1[0]
                        );
                        this.onChangeRelationShip();
                    }
                }
            },
            () => {
                this.isEditCustomerShowSkeleton = false;
            }
        );
    }

    onChangeRelationShip() {
        if (this.userFormGroup.controls['married'].value == 2) {
            this.isWeddingElementEnabled = true;
        } else {
            this.isWeddingElementEnabled = false;
        }
    }

    private showMessageModal(message: string) {
        this.eventService.emitEvent(
            EventType.MODAL_INFO,
            this.translate.instant(message)
        );
    }

    updateProfile() {
        if (
            isNullOrWhiteSpace(this.userFormGroup.controls['firstname'].value)
        ) {
            this.showMessageModal('Please enter the first name');
            return;
        }
        if (isNullOrWhiteSpace(this.userFormGroup.controls['lastname'].value)) {
            this.showMessageModal('Please enter the last name');
            return;
        }

        if (
            isNullOrWhiteSpace(this.userFormGroup.controls['dobmonth'].value) ||
            isNullOrWhiteSpace(this.userFormGroup.controls['dobdate'].value) ||
            isNullOrWhiteSpace(this.userFormGroup.controls['dobyear'].value)
        ) {
            this.showMessageModal('Please enter the date of birth');
            return;
        }
        if (
            isNaN(this.userFormGroup.controls['dobdate'].value) ||
            isNaN(this.userFormGroup.controls['dobyear'].value)
        ) {
            this.showMessageModal('Date must be a number');
            return;
        }

        // if (this.isWeddingElementEnabled) {
        //     if (
        //         isNullOrWhiteSpace(
        //             this.userFormGroup.controls['wedmonth'].value
        //         )
        //     ) {
        //         this.showMessageModal('Please enter the anniversary date');
        //         return;
        //     }
        //     if (
        //         isNullOrWhiteSpace(this.userFormGroup.controls['weddate'].value)
        //     ) {
        //         this.showMessageModal('Please enter the anniversary date');
        //         return;
        //     }
        //     if (
        //         isNullOrWhiteSpace(this.userFormGroup.controls['wedyear'].value)
        //     ) {
        //         this.showMessageModal('Please enter the anniversary date');
        //         return;
        //     }
        // }

        const data = this.userFormGroup.getRawValue();
        // let wed_ann: string = '';
        // let dob: string = '';

        // if (data.dobmonth && data.dobdate && data.dobyear) {
        //     dob = data.dobmonth + '/' + data.dobdate + '/' + data.dobyear;
        // }
        // if (data.wedmonth && data.weddate && data.wedyear) {
        //     wed_ann = data.wedmonth + '/' + data.weddate + '/' + data.wedyear;
        // }

        const newData = {
            billfname: data.firstname,
            billlname: data.lastname,
            billstreet1: data.street,
            billstreet2: '',
            billcity: '',
            billstate: '',
            billcountry: data.countryid,
            billlocation: data.location,
            billgender: data.gender,
            billmarried: data.married,
            billchildren: data.children,
            // billdob: dob,
            // wed_anniversary: wed_ann,
            preferred_holiday_options: data.preferred_holiday_options,
            preferred_holiday_values: this.preferred_holiday_values,
        };

        const base64Data = this.common.encode(JSON.stringify(newData));
        const email = this.common.encode(JSON.stringify({ email: data.email }));
        const payload = {
            billaddress: base64Data,
            customerid: this.customerId,
            shippaddress: '',
            profile: email,
            preferred_language: this.selectedLang,
        };
        this.common.startProgress();
        this.changeLanguage();
        this.editService.update_billing_address_api(payload).subscribe(
            (response: any) => {
                this.common.endProgress();
                this.sectionBeingEdited = '';
                if (response.billaddress == 1) {
                    this.showMessageModal('Your profile has been updated');
                } else {
                    this.showMessageModal(response.errorMessage);
                    this.revertLanguage();
                }
            },
            () => {
                this.revertLanguage();
                this.common.endProgress();
            }
        );
    }

    changeLanguage() {
        this.common.setCache(this, 'language', this.selectedLang);
        this.translate.use(this.selectedLang);
    }

    revertLanguage() {
        this.common.setCache(
            this,
            'language',
            this.selectedLang == 'en' ? 'fr' : 'en'
        );
        this.translate.use(this.selectedLang == 'en' ? 'fr' : 'en');
    }

    get name() {
        return `${this.userFormGroup.controls['firstname'].value} ${this.userFormGroup.controls['lastname'].value}`;
    }
    get email() {
        return this.userFormGroup.controls['email'].value;
    }
    get telephone() {
        return this.userFormGroup.controls['telephone'].value;
    }

    get dob() {
        const dobMonth = this.userFormGroup.controls['dobmonth'].value;
        const dobDate = this.userFormGroup.controls['dobdate'].value;
        const dobYear = this.userFormGroup.controls['dobyear'].value;
        const dob = dayjs(`${dobMonth}/${dobDate}/${dobYear}`).format(
            'DD/MM/YYYY'
        );
        return dob;
    }

    get gender() {
        return this.userFormGroup.controls['gender'].value;
    }
    get address() {
        return `${this.userFormGroup.controls['street'].value}, ${this.billing.city}`;
    }

    verifyEmail() {
        if (this.userFormGroup.controls['email'].status == 'INVALID') {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                this.translate.instant("Email id can't be empty")
            );
            return;
        } else {
            this.settingsService
                .verifyEmail(this.customerId)
                .subscribe((response: any) => {
                    if (response.status == 'success') {
                        this.otpModal.open();
                    } else if (response.status == 'error') {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.message
                        );
                        return;
                    }
                });
        }
    }

    emailOtpInputChange(event: any) {
        if (event.target.value.length == 4) {
            this.verifyEmailOtp();
        }
    }

    verifyEmailOtp() {
        let otp = this.emailVerifyOtpForm.controls['emailVerifyOtp'].value;
        this.settingsService
            .verifyEmailOtp(this.customerId, otp)
            .subscribe((response: any) => {
                if (response.status == 'error') {
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        this.translate.instant('Please enter correct OTP')
                    );
                } else if (response.status == 'success') {
                    this.otpModal.close();
                    this.mailVerify = true;
                    this.getCustomerVerificationInfo();
                }
            });
    }

    resendEmailOtp() {
        this.verifyEmail();
    }

    getCustomerVerificationInfo() {
        this.settingsService
            .getCustomerVerificationInfo(this.customerId)
            .subscribe(
                (response: any) => {
                    this.customerVerificationInfoResponse = response;
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    updateProfilePhoneAndEmail() {
        this.verifyMobile();
        if (
            this.customerVerificationInfoResponse.customer_mobile ==
            this.userFormGroup.controls['telephone'].value
        ) {
            if (
                this.userFormGroup.controls['email'].value != '' &&
                this.userFormGroup.controls['email'].value !=
                    this.customerVerificationInfoResponse.customer_email &&
                this.userFormGroup.controls['email'].status != 'INVALID'
            ) {
                let encodedMobileObj = btoa(
                    JSON.stringify({
                        billphone:
                            this.userFormGroup.controls['telephone'].value,
                    })
                );
                let encodedEmailObj = btoa(
                    JSON.stringify({
                        email: this.userFormGroup.controls['email'].value,
                    })
                );
                this.settingsService
                    .mofluidUpdateEmailMobile(
                        this.customerId,
                        encodedMobileObj,
                        encodedEmailObj
                    )
                    .subscribe((response: any) => {
                        this.sectionBeingEdited = '';
                        this.mailVerify = false;
                        this.getCustomerVerificationInfo();
                    });
            }
        }
    }

    verifyMobile() {
        if (
            this.customerVerificationInfoResponse.customer_mobile !=
            this.userFormGroup.controls['telephone'].value
        ) {
            if (this.userFormGroup.controls['telephone'].value.length < 6) {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant("Mobile number can't be empty")
                );
                return;
            } else {
                let encodedNewMob = btoa(
                    this.userFormGroup.controls['telephone'].value
                );
                this.settingsService
                    .verifyMobile(this.customerId, encodedNewMob)
                    .subscribe((response: any) => {
                        if (response.status == 'success') {
                            this.verifyMobileOtpPopup = true;
                        } else if (response.status == 'error') {
                            this.eventService.emitEvent(
                                EventType.MODAL_ERROR,
                                response.message
                            );
                            return;
                        }
                    });
            }
        }
    }

    mobileOtpInputChange(event: any) {
        if (event.target.value.length == 4) {
            $('#login-otp-input').attr('disabled', 'disabled');
            this.verifyMobileOtp();
        }
    }

    verifyMobileOtp() {
        let otp = this.mobileVerifyOtpForm.controls['mobileVerifyOtp'].value;
        let encodedNewMob = btoa(
            this.userFormGroup.controls['telephone'].value?.e164Number
        );
        this.settingsService
            .verifyMobileOtp(this.customerId, otp, encodedNewMob)
            .subscribe((response: any) => {
                if (response.status == 'error') {
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        this.translate.instant('Please enter correct OTP')
                    );
                } else if (response.status == 'success') {
                    this.verifyMobileOtpPopup = false;
                    if (
                        this.userFormGroup.controls['email'].value != '' &&
                        this.userFormGroup.controls['email'].value !=
                            this.customerVerificationInfoResponse
                                .customer_email &&
                        this.userFormGroup.controls['email'].status != 'INVALID'
                    ) {
                        let encodedMobileObj = btoa(
                            JSON.stringify({
                                billphone:
                                    this.userFormGroup.controls['mobile'].value,
                            })
                        );
                        let encodedEmailObj = btoa(
                            JSON.stringify({
                                email: this.userFormGroup.controls['email']
                                    .value,
                            })
                        );
                        this.settingsService
                            .mofluidUpdateEmailMobile(
                                this.customerId,
                                encodedMobileObj,
                                encodedEmailObj
                            )
                            .subscribe((response: any) => {
                                this.getCustomerVerificationInfo();
                            });
                    }
                }
            });
    }

    resendMobileOtp() {
        this.verifyMobile();
    }

    protected readonly ProfileSection = ProfileSection;
}
