import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import {
    FacebookLoginProvider,
    SocialAuthService,
} from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/common.service';
import { DataService } from 'src/app/shared/data.service';
import { LoginService } from '../login/login.service';
import { TokenService } from '../services/token.service';
import { SocialService } from '../login-register/shared-service/social.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceCommsService } from '../services/device-comms.service';
import { MdDeviceDetectorService } from '../services/device-detector.service';
import { SocialLogin } from './model/social-login.model';
import { User } from '../model/user.model';
import { setBadgeCount, setRewardPoints } from '../utilities/user.utils';

@Component({
    selector: 'md-app-social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {
    public cdn = '';
    isMobileApp = false;

    constructor(
        private socialAuthService: SocialAuthService,
        public router: Router,
        private socialService: SocialService,
        private deviceDetectorService: MdDeviceDetectorService,
        private deviceCommsService: DeviceCommsService,
        private spinner: NgxSpinnerService,
        private common: CommonService,
        private dataService: DataService,
        private loginService: LoginService,
        private tokenService: TokenService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.isMobileApp = !this.deviceDetectorService.isBrowser;

        this.socialAuthService.authState.subscribe((userData: SocialLogin) => {
            if (userData) {
                this.socialService
                    .socialLoginApi(userData)
                    .subscribe((response: User) => {
                        if (response.jwtToken) {
                            this.setLang(response);
                            this.spinner.show();
                            this.common.side_drawer_user_initial =
                                response.firstName.split('')[0];
                            this.common.side_drawer_user =
                                response.firstName + ' ' + response.lastName;
                            this.common.side_drawer_email = response.username
                                ? response.username
                                : response.email;
                            // this.common.badges = response.availableBadges;
                            setBadgeCount(response.availableBadges);
                            // this.common.balancePoints = response.rewardData.balancePoints;
                            setRewardPoints(response.rewardData.balancePoints);
                            this.common.isLoggedIn = true;
                            this.tokenService.saveJwtToken(response.jwtToken);
                            this.common.setCache(this, 'user', response);
                            localStorage.setItem(
                                'customerId',
                                window.btoa(response.id)
                            );
                            this.loginService.mergeCart();
                        }
                    });
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).hadleSocialLogin = this.hadleSocialLogin.bind(this);
    }

    setLang(data: User) {
        if (parseInt(data.groupId) == 8) {
            this.common.setCache(this, 'is_reunion', true);
            this.common.setCustomerGroupID(data.groupId);

            if (data.preferredLanguage) {
                this.translate.setDefaultLang(data.preferredLanguage);
                this.common.setCache(this, 'language', data.preferredLanguage);
            } else {
                this.translate.setDefaultLang(
                    this.common.getCache(this, 'language')
                        ? this.common.getCache(this, 'language').data
                        : 'en'
                );
                this.common.setCache(
                    this,
                    'language',
                    this.common.getCache(this, 'language')
                        ? this.common.getCache(this, 'language').data
                        : 'en'
                );
            }

            this.common.setCache(this, 'storeID', 2);
            this.common.language = 'fr-reunion';
        } else if (parseInt(data.groupId) == 11) {
            this.common.setCache(this, 'is_reunion', false);

            this.common.setCustomerGroupID(parseInt(data.groupId));
            this.translate.setDefaultLang('en');
            this.common.setCache(this, 'storeID', 1);
            this.common.language = 'fr-reunion';
            this.common.setCache(this, 'language', 'en');
        } else {
            this.common.setCache(this, 'is_reunion', false);
            this.common.language = this.common.getCache(this, 'language')
                ? this.common.getCache(this, 'language').data
                : '';

            if (!this.common.language) {
                this.common.setCustomerGroupID(parseInt(data.groupId));
                this.translate.setDefaultLang('en');
                this.common.setCache(this, 'storeID', 1);
                this.common.language = 'en';
                this.common.setCache(this, 'language', 'en');
            } else {
                this.common.setCustomerGroupID(parseInt(data.groupId));
                if (this.common.language == 'en') {
                    this.translate.setDefaultLang('en');
                    this.common.setCache(this, 'storeID', 1);
                    this.common.setCache(this, 'language', 'en');
                    this.common.language = 'en';
                } else {
                    this.translate.setDefaultLang('fr');
                    this.common.setCache(this, 'storeID', 2);
                    this.common.setCache(this, 'language', 'fr');
                    this.common.language = 'fr';
                }
            }
        }
    }

    loginWithFacebook(): void {
        if (!this.deviceDetectorService.isBrowser) {
            this.deviceCommsService.facebookLogin();
        } else {
            this.socialAuthService
                .signIn(FacebookLoginProvider.PROVIDER_ID)
                .then((userData: SocialLogin) => {
                    this.hadleSocialLogin(userData);
                });
        }
    }

    hadleSocialLogin(userData) {
        userData = JSON.parse(userData);
        const first_name = userData.name.split(' ')[0];
        const last_name = userData.name.substring(first_name.length).trim();
        userData = {
            firstName: first_name,
            lastName: last_name,
            email: userData.email,
            id: userData.socialId,
            provider: userData.provider,
            is_app_login: this.isMobileApp,
        };
        this.common.setCache(this, 'socialLoginType', userData.provider);

        this.socialService
            .socialLoginApi(userData)
            .subscribe((response: User) => {
                if (response.jwtToken) {
                    this.setLang(response);
                    this.spinner.show();
                    this.common.side_drawer_user_initial =
                        response.firstName.split('')[0];
                    this.common.side_drawer_user =
                        response.firstName + ' ' + response.lastName;
                    this.common.side_drawer_email = response.username
                        ? response.username
                        : response.email;
                    // this.common.badges = response.availableBadges;
                    setBadgeCount(response.availableBadges);
                    // this.common.balancePoints =
                    //     response.rewardData.balancePoints;
                    setRewardPoints(response.rewardData.balancePoints);
                    this.common.isLoggedIn = true;
                    this.common.setCache(this, 'user', response);
                    localStorage.setItem(
                        'customerId',
                        window.btoa(response.id)
                    );
                    const dataStore = this.common.getDataStoreObj();
                    dataStore['loginResponse'] = response;
                    this.tokenService.saveJwtToken(response.jwtToken);
                    this.dataService.setData(dataStore);
                    this.loginService.mergeCart();
                }
            });
    }

    loginWithGoogle(): void {
        this.deviceCommsService.googleLogin();
    }

    checkIsiOSWrapperApp() {
        return this.deviceDetectorService.isIos;
    }

    loginWithApple() {
        this.deviceCommsService.appleLogin();
    }
}
