<ng-template #actualControl>
    <ng-content select="[actualControl]"></ng-content>
</ng-template>
<ng-template #mobileControl let-offcanvas>
    <div class="cko-payment-modal-header">
        <button
            type="button"
            class="cko-payment-modal-close"
            aria-label="Close"
            (click)="close()"
        >
            <ion-icon name="close-outline"></ion-icon>
        </button>
        <h4 class="modal-title">{{ mobileModalTitle }}</h4>
    </div>
    <div class="c-po-modal-content">
        <ng-container *ngTemplateOutlet="actualControl"></ng-container>
    </div>
</ng-template>
<ng-template #modal let-modal>
    <div class="modal-header cko-payment-modal-header">
        <button
            type="button"
            class="cko-payment-modal-close"
            aria-label="Close"
            (click)="modal.dismiss()"
        >
            <ion-icon name="close-outline"></ion-icon>
        </button>
        <h4 class="modal-title">{{ mobileModalTitle }}</h4>
    </div>
    <div
        class="modal-body cko-modal-body"
        [ngClass]="{ thinPadding: thinPadding }"
    >
        <ng-container *ngTemplateOutlet="actualControl"></ng-container>
    </div>
</ng-template>
