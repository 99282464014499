// ribbon-style.service.ts
import { Injectable } from '@angular/core';
import { Product } from '../category-deals-list/model/product.model';

@Injectable({
  providedIn: 'root'
})
export class RibbonStyleService {
  getRibbonStyles(dealEntry: Product) {
    if (!dealEntry || !dealEntry.ribbon) {
      return {};
    }

    const ribbon = dealEntry.ribbon;
    const textColor = ribbon.ribbonName.toUpperCase() === 'FLASH SALES'
        ? '#ffffff' 
        : '#092e41';

    return {
      color: textColor,
      background: ribbon.colorCode
    };
  }
}
