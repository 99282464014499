import { Component, TemplateRef, ViewChild } from '@angular/core';
import { EventService, EventType } from '../utilities/event.service';
import { screenSizes } from '../utilities/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LoginSuccessService } from '../login/login-success.service';

@Component({
    selector: 'md-events-wrapper',
    templateUrl: 'events-wrapper.component.html',
})
export class EventsWrapperComponent {
    messageModalText = '';

    private callbacks = new Map<string, () => void>();

    @ViewChild('login') loginModal: TemplateRef<never>;
    @ViewChild('register') registerModal: TemplateRef<never>;
    @ViewChild('messageModal') messageModal: TemplateRef<never>;
    @ViewChild('forgottenPassword') forgottenPasswordModal: TemplateRef<never>;

    constructor(
        private eventService: EventService,
        private loginSuccessService: LoginSuccessService,
        private modal: NgbModal,
        private router: Router
    ) {
        this.subscribeForLogin();
        this.subscribeForRegister();
        this.subscribeForError();
        this.subscribeForModalInfo();
        this.subscribeForForgottenAndChangePassword();
    }

    executeModalCallback(): void {
        if (this.callbacks.has('modal') && this.callbacks.get('modal')) {
            this.callbacks.get('modal')();
        }
    }

    // when this loads it has to look at the url.
    // If the URL contains something about login it should open it up and remove from URL
    // then we need a service that, if login is done and the url contains something, it needs to redirect
    private subscribeForLogin(): void {
        this.eventService.onEvent(EventType.LOGIN, (payload) => {
            const width = window.innerWidth;
            this.modal.dismissAll();
            if (width > screenSizes.mobile) {
                this.modal.open(this.loginModal, {
                    modalDialogClass: 'lgn-modal',
                    ariaLabelledBy: 'login',
                    size: 'md',
                    centered: true,
                });
            } else {
                this.router.navigate(['login']);
            }
            this.loginSuccessService.loginSuccessRedirectPath =
                payload?.message;
        });
        this.eventService.onEvent(EventType.LOGIN_SUCCESS, () => {
            this.modal.dismissAll();
        });
    }

    private subscribeForRegister(): void {
        this.eventService.onEvent(EventType.REGISTER, () => {
            const width = window.innerWidth;
            this.modal.dismissAll();
            if (width > screenSizes.mobile) {
                this.modal.open(this.registerModal, {
                    modalDialogClass: 'rgtr-modal',
                    ariaLabelledBy: 'register',
                    keyboard: false,
                    size: 'md',
                    centered: true,
                });
            } else {
                this.router.navigate(['register']);
            }
        });
    }

    private subscribeForError(): void {
        this.eventService.onEvent(EventType.MODAL_ERROR, (payload) => {
            this.callbacks.set('modal', payload?.callback);
            this.messageModalText = payload?.message || 'Error';
            this.modal.open(this.messageModal, {
                ariaLabelledBy: 'error-modal',
                modalDialogClass: 'error-modal',
                size: 'sm',
                centered: true,
            });
        });
    }

    private subscribeForModalInfo(): void {
        this.eventService.onEvent(EventType.MODAL_INFO, (payload) => {
            this.callbacks.set('modal', payload?.callback);
            this.messageModalText = payload?.message || 'Info';
            this.modal.open(this.messageModal, {
                ariaLabelledBy: 'info-modal',
                modalDialogClass: 'info-modal',
                size: 'sm',
                centered: true,
            });
        });
    }

    private subscribeForForgottenAndChangePassword(): void {
        this.eventService.onEvent(EventType.FORGOTTEN_PASSWORD, () => {
            const width = window.innerWidth;
            this.modal.dismissAll();
            if (width > screenSizes.mobile) {
                this.modal.open(this.forgottenPasswordModal, {
                    modalDialogClass: 'frgt-pass-modal',
                    ariaLabelledBy: 'forgotten-password',
                    size: 'md',
                    centered: true,
                });
            } else {
                this.router.navigate(['password']);
            }
        });
        this.eventService.onEvent(EventType.CHANGE_PASSWORD, () =>
            this.modal.dismissAll()
        );
    }
}
