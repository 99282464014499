import { NgModule } from '@angular/core';
import { EventsWrapperComponent } from './events-wrapper.component';
import { LoginModule } from '../login/login.module';
import { RegisterModule } from '../register/register.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ForgottenPasswordModule } from '../forgotten-password/forgotten-password.module';
import { ControlsModule } from '../controls/controls.module';

@NgModule({
    imports: [
        LoginModule,
        RegisterModule,
        TranslateModule,
        NgxSpinnerModule,
        ForgottenPasswordModule,
        ControlsModule,
    ],
    declarations: [EventsWrapperComponent],
    exports: [EventsWrapperComponent],
})
export class EventsModule {}
