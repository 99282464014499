import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    DEFAULT_CURRENCY_CODE,
    ErrorHandler,
    NgModule,
    LOCALE_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { Router, RouterModule } from '@angular/router';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserModule } from './user/user.module';
import { SkeletonComponent } from './skeleton/skeleton.component';
import {
    CommonModule,
    HashLocationStrategy,
    LocationStrategy,
    NgOptimizedImage,
    PathLocationStrategy,
} from '@angular/common';
import {
    FacebookLoginProvider,
    GoogleLoginProvider,
    GoogleSigninButtonDirective,
    GoogleSigninButtonModule,
    SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { CategoryDealsListModule } from './category-deals-list/category-deals-list.module';
import { TimerComponent } from './timer/timer.component';
import { EgiftCardComponent } from './egift-card/egift-card.component';
import { PlatformModule } from '@angular/cdk/platform';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { MapComponent } from './product-detail/map/map.component';
import { MyInitDirective } from './my-init.directive';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CheckoutComponent } from './user/checkout/checkout.component';
import { WinComponent } from './win/win.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdDatepickerPopupModule } from './datepicker-popup/datepicker-popup.module';
import { NoInternetComponent } from './no-internet/no-internet.component';
import { LanguageModalPopupComponent } from './language-modal-popup/language-modal-popup.component';
import { ControlsModule } from './controls/controls.module';
import { GiftCardForm } from './egift-card/gc-form/gc-form.component';
import { HttpErrorCatchingInterceptor } from './interceptors/http-error-catching.interceptor';
import * as Sentry from '@sentry/angular-ivy';
import { HomeComponent } from './home/home.component';
import { HomeBannerComponent } from './home/home-banner/home-banner.component';
import { HeaderSearchBarComponent } from './header/header-search-bar/header-search-bar.component';
import { EmptyCartComponent } from './user/checkout/empty-cart/empty-cart.component';
import { ReservationDetailsComponent } from './user/checkout/reservation-details/reservation-details.component';
import { CartItemsComponent } from './user/checkout/order-summary/cart-items/cart-items.component';
import { CheckoutLoadingComponent } from './user/checkout/checkout-loading/checkout-loading.component';
import { ProductImageCarouselComponent } from './product-detail/carousel/product-image-carousel.component';
import { BookingConfigComponent } from './product-detail/booking-config/booking-config.component';
import { SubtotalComponent } from './product-detail/subtotal/subtotal.component';
import { HotelBookingComponent } from './product-detail/booking-config/hotel-booking/hotel-booking.component';
import { ActivityBookingComponent } from './product-detail/booking-config/activity-booking/activity-booking.component';
import { DatedActivityBookingComponent } from './product-detail/booking-config/dated-activity-booking/dated-activity-booking.component';
import { RewardsComponent } from './product-detail/rewards/rewards.component';
import { ProductStaticContentComponent } from './product-detail/static-content/static-content.component';
import { ForgottenPasswordModule } from './forgotten-password/forgotten-password.module';
import { EventsModule } from './events/events.module';
import { MoreDealsComponent } from './product-detail/more-deals/more-deals.component';
import { CustomersAlsoViewedComponent } from './product-detail/customers-also-viewed/customers-also-viewed.component';
import { SpaBookingComponent } from './product-detail/booking-config/spa-booking/spa-booking.component';
import { ProductDetailLoadingComponent } from './product-detail/product-detail.loading';
import { MaribnbBookingComponent } from './product-detail/booking-config/maribnb-booking/maribnb-booking.component';
import { AddOnsComponent } from './product-detail/booking-config/add-ons/add-ons.component';
import { SpecialOffersComponent } from './product-detail/booking-config/special-offers/special-offers.component';
import { RecentlyViewedComponent } from './product-detail/recently-viewed/recently-viewed.component';
import { ScreenSizeUtilComponent } from './utilities/screen-size-util/screen-size-util.component';
import { registerLocaleData } from '@angular/common';
import localeEnMu from '@angular/common/locales/en-MU';
import localeFrRe from '@angular/common/locales/fr-RE';
import { ComponentsModule } from './components/components.module';
import { DealInformationComponent } from './product-detail/static-content/deal-information/deal-information.component';
import { DealConditionsComponent } from './product-detail/static-content/deal-conditions/deal-conditions.component';
import { DealFacilitiesComponent } from './product-detail/static-content/deal-facilities/deal-facilities.component';
import { DealLocationComponent } from './product-detail/static-content/deal-location/deal-location.component';
import { SearchComponent } from './search/search.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilterDataModule } from './filter-data/filter-data.module';
import { BookingSummaryComponent } from './user/checkout/order-summary/booking-summary/booking-summary.component';
import { OrderSummaryComponent } from './user/checkout/order-summary/order-summary.component';
import { MipsLoading } from './user/checkout/mips-loading/mips-loading.component';
import { CoreModule } from './core/core.module';

registerLocaleData(localeEnMu, 'en-MU');
registerLocaleData(localeFrRe, 'fr-RE');

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        NavbarComponent,
        FooterComponent,
        SidebarComponent,
        SkeletonComponent,
        EgiftCardComponent,
        GiftCardForm,
        TimerComponent,
        ProductDetailComponent,
        MapComponent,
        MyInitDirective,
        CheckoutComponent,
        WinComponent,
        NoInternetComponent,
        LanguageModalPopupComponent,
        HomeComponent,
        HomeBannerComponent,
        HeaderSearchBarComponent,
        EmptyCartComponent,
        ReservationDetailsComponent,
        BookingSummaryComponent,
        CartItemsComponent,
        CheckoutLoadingComponent,
        ScreenSizeUtilComponent,
        ProductImageCarouselComponent,
        BookingConfigComponent,
        HotelBookingComponent,
        ActivityBookingComponent,
        DatedActivityBookingComponent,
        SubtotalComponent,
        RewardsComponent,
        ProductStaticContentComponent,
        MoreDealsComponent,
        CustomersAlsoViewedComponent,
        SpaBookingComponent,
        ProductDetailLoadingComponent,
        MaribnbBookingComponent,
        AddOnsComponent,
        SpecialOffersComponent,
        RecentlyViewedComponent,
        DealInformationComponent,
        DealConditionsComponent,
        DealFacilitiesComponent,
        DealLocationComponent,
        SearchComponent,
        OrderSummaryComponent,
        MipsLoading,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        RouterModule,
        NgxUsefulSwiperModule,
        HttpClientModule,
        LazyLoadImageModule,
        FormsModule,
        UserModule,
        ForgottenPasswordModule,
        ReactiveFormsModule,
        CategoryDealsListModule,
        PlatformModule,
        Ng2SearchPipeModule,
        NgxSpinnerModule,
        NgxGoogleAnalyticsRouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgxIntlTelInputModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        NgbModule,
        NgbdDatepickerPopupModule,
        ControlsModule,
        GoogleSigninButtonModule,
        NgbPopoverModule,
        NgOptimizedImage,
        CommonModule,
        CoreModule,
        EventsModule,
        ComponentsModule,
        InfiniteScrollModule,
        FilterDataModule,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'MUR',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorCatchingInterceptor,
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('3763197587093417'), // live 3763197587093417
                    },
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '279896006341-95tjbtj1so7meb37jndgtg2ebg41rpo8.apps.googleusercontent.com',
                            {
                                oneTapEnabled: false,
                            }
                        ), // live
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'en-MU' },
        GoogleSigninButtonDirective,
        [
            Location,
            {
                provide: LocationStrategy,
                useClass: PathLocationStrategy,
            }, // TODO: do we need both location strategies?
        ],
    ],
    exports: [LanguageModalPopupComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
