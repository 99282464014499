import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, merge, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventService {
    private eventHandlers: Map<string, EventHandler> = new Map();
    private emittedEvents: Set<string> = new Set();

    emitEvent(eventType: EventType, eventValue?: Event | string): void {
        const payload: Event =
            eventValue && typeof eventValue === 'string'
                ? ({ message: eventValue } as Event)
                : (eventValue as Event);
        this.getOrCreateEventHandler(eventType).subject.next(payload);
        this.emittedEvents.add(eventType);
    }

    onEvent(
        eventType: EventType,
        handler: (value: Event) => void
    ): Subscription {
        return this.getOrCreateEventHandler(eventType).observable.subscribe(
            handler
        );
    }

    onEventOrAlreadyHappened(eventType: EventType): Observable<Event> {
        const eventHandler = this.getOrCreateEventHandler(eventType);
        // Check if the event has already been emitted
        if (this.emittedEvents.has(eventType)) {
            // Return an observable that emits the last emitted event immediately
            return merge(of(eventHandler.lastEvent), eventHandler.observable);
        }
        return eventHandler.observable;
    }

    private getOrCreateEventHandler(eventName: string): EventHandler {
        if (!eventName) {
            throw new Error('Event name should be specified.');
        }

        let eventHandler = this.eventHandlers.get(eventName);
        if (!eventHandler) {
            eventHandler = new EventHandler();
            this.eventHandlers.set(eventName, eventHandler);
        }

        return eventHandler;
    }
}

class EventHandler {
    subject: Subject<Event>;
    observable: Observable<Event>;
    lastEvent: Event;

    constructor() {
        this.subject = new Subject<Event>();
        this.observable = this.subject.asObservable();
        this.observable.subscribe((event) => {
            this.lastEvent = event; // Update lastEvent when a new event is emitted
        });
    }
}

export interface Event {
    message: string;
    callback: () => void;
}

export enum EventType {
    LOGIN = 'login',
    REGISTER = 'register',
    MODAL_ERROR = 'error',
    MODAL_INFO = 'modal-info',
    LOGIN_SUCCESS = 'login-success',
    LOGIN_FAILED = 'login-failed',
    FORGOTTEN_PASSWORD = 'forgotten-password',
    CHANGE_PASSWORD = 'change-password',
    AUTH_LOADED = 'auth-loaded',
}
