import {
    Component,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { RegisterService } from './register.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Locale } from 'src/app/config/locale';
import { CommonService } from '../shared/common.service';
import { LoginService } from '../login/login.service';
import { finalize } from 'rxjs';
import { getCache, setCache } from '../utilities/cache.utils';
import { EventService, EventType } from '../utilities/event.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceCommsService } from '../services/device-comms.service';
import {
    CreateUserDto,
    RegisterResponseDto,
    ResendOtpResponseDto,
} from './model/register.model';
import { Country } from '@capgo/ngx-intl-tel-input/lib/model/country.model';
import {
    getUser,
    setBadgeCount,
    setRewardPoints,
} from '../utilities/user.utils';

export interface MDCountry {
    name: string;
    code: string;
}

@Component({
    selector: 'md-app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    private readonly MAURITIUS = { name: 'Mauritius', code: 'mu' } as MDCountry;
    private readonly REUNION = { name: 'Reunion', code: 're' } as MDCountry;
    private readonly OTHER_COUNTRY = { name: 'Others', code: '2' } as MDCountry;

    registerForm!: UntypedFormGroup;
    showHidePass = false;
    shouldDesiplayOtherCountriesSelect = false;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.Mauritius,
        CountryISO.Réunion,
    ];
    otpMobileNumber: string;
    selectedCountryCode = CountryISO.Mauritius;
    countries = [this.MAURITIUS, this.REUNION, this.OTHER_COUNTRY];
    currCountry: string;
    passwordInputType = 'password';
    passwordIconClass = 'fa-eye';

    firstNameClass = '';
    firstNameErrorClass = '';
    lastNameClass = '';
    lastNameErrorClass = '';
    emailClass = '';
    emailErrorClass = '';
    mobileClass = '';
    mobileErrorClass = '';
    passwordClass = '';
    passwordErrorClass = '';

    @ViewChild('verifyOtpAccount') verifyOtpAccountModal: TemplateRef<never>;

    @ViewChild('email') emailInput: ElementRef;
    @ViewChild('firstName') firstNameInput: ElementRef;
    @ViewChild('lastName') lastNameInput: ElementRef;
    @ViewChild('password') passwordInput: ElementRef;

    constructor(
        private elRef: ElementRef,
        private eventService: EventService,
        private registerService: RegisterService,
        private deviceCommsService: DeviceCommsService,
        private route: ActivatedRoute,
        private router: Router,
        private modal: NgbModal,
        private spinner: NgxSpinnerService,
        private commonService: CommonService,
        private loginService: LoginService,
        private tokenService: TokenService,
        private translate: TranslateService,
        private httpClient: HttpClient,
        private localeObj: Locale
    ) {}

    ngOnInit(): void {
        this.checkForLoggedUser();
        this.registerForm = new UntypedFormGroup({
            firstName: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.email,
                Validators.pattern(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
            ]),
            country: new UntypedFormControl('', [Validators.required]),
            others: new UntypedFormControl('', []),
            mobile: new UntypedFormControl('', [Validators.required]),
            dialCode: new UntypedFormControl('', []),
            countryCode: new UntypedFormControl('', []),
            otp: new UntypedFormControl('', []),
            customerId: new UntypedFormControl('', []),
            password: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
        });
        this.registerForm.controls['country'].setValue('mu');

        this.route.queryParams.subscribe({
            next: (params) => {
                this.currCountry = params['iso']?.includes('RE')
                    ? 'Reunion'
                    : 'Mauritius';
                if (this.currCountry == 'Mauritius') {
                    this.registerForm.controls['country'].setValue(
                        this.MAURITIUS.code
                    );
                    this.selectedCountryCode = CountryISO.Mauritius;
                } else if (this.currCountry == 'Reunion') {
                    this.registerForm.controls['country'].setValue(
                        this.REUNION.code
                    );
                    this.selectedCountryCode = CountryISO.Réunion;
                } else {
                    this.registerForm.controls['country'].setValue(
                        this.OTHER_COUNTRY.code
                    );
                    this.selectedCountryCode = CountryISO.Mauritius;
                }
            },
        });
    }

    checkForLoggedUser() {
        const userdata = getUser();
        if (userdata && userdata.isSuccess) {
            this.router.navigate(['/']);
        }
    }

    onFirstNameChange(): void {
        this.registerForm.controls['firstName'].setValue(
            this.registerForm.controls['firstName'].value.replace(
                /[^A-Za-z -]/g,
                ''
            )
        );
        this.firstNameClass = this.registerForm.controls['firstName'].value
            ? 'has-input'
            : '';
        if (this.firstNameErrorClass) {
            this.firstNameErrorClass = '';
        }
    }

    onLastNameChange(): void {
        this.registerForm.controls['lastName'].setValue(
            this.registerForm.controls['lastName'].value.replace(
                /[^A-Za-z -]/g,
                ''
            )
        );
        this.lastNameClass = this.registerForm.controls['lastName'].value
            ? 'has-input'
            : '';
        if (this.lastNameErrorClass) {
            this.lastNameErrorClass = '';
        }
    }

    onEmailChange(): void {
        this.emailClass = this.registerForm.controls['email'].value
            ? 'has-input'
            : '';
        if (this.emailErrorClass) {
            this.emailErrorClass = '';
        }
    }

    onMobileChange(): void {
        this.mobileClass = this.registerForm.controls['mobile'].value
            ? 'has-input'
            : '';
        if (this.mobileErrorClass) {
            this.mobileErrorClass = '';
        }
    }

    onPasswordChange(): void {
        this.passwordClass = this.registerForm.controls['password'].value
            ? 'has-input'
            : '';
        if (this.passwordErrorClass) {
            this.passwordErrorClass = '';
        }
    }

    validateFirstName(shouldFocus = false): boolean {
        if (this.registerForm.controls['firstName'].invalid) {
            if (shouldFocus) {
                this.firstNameInput.nativeElement.focus();
            }
            this.firstNameErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validateLastName(shouldFocus = false): boolean {
        if (this.registerForm.controls['lastName'].invalid) {
            if (shouldFocus) {
                this.lastNameInput.nativeElement.focus();
            }
            this.lastNameErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validateEmail(shouldFocus = false): boolean {
        if (this.registerForm.controls['email'].invalid) {
            if (shouldFocus) {
                this.emailInput.nativeElement.focus();
            }
            this.emailErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validateMobile(shouldFocus = false): boolean {
        if (this.registerForm.controls['mobile'].invalid) {
            if (shouldFocus) {
                document.getElementById('phone').focus();
            }
            this.mobileErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validatePassword(shouldFocus = false): boolean {
        if (this.registerForm.controls['password'].invalid) {
            if (shouldFocus) {
                this.passwordInput.nativeElement.focus();
            }
            this.passwordErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    submitRegistrationForm() {
        if (this.registerForm.invalid) {
            let shouldFocus = true;
            shouldFocus = this.validateFirstName(shouldFocus);
            shouldFocus = this.validateLastName(shouldFocus);
            shouldFocus = this.validateEmail(shouldFocus);
            shouldFocus = this.validateMobile(shouldFocus);
            this.validatePassword(shouldFocus);

            if (this.registerForm.controls['country'].status == 'INVALID') {
                this.eventService.emitEvent(
                    EventType.MODAL_ERROR,
                    this.translate.instant('Please select country')
                );
                return;
            }

            if (
                this.registerForm.controls['mobile']?.value?.number == '' ||
                this.registerForm.controls['mobile']?.value?.number == null ||
                this.registerForm.controls['mobile']?.status == 'INVALID'
            ) {
                if (
                    this.registerForm.controls['mobile']?.value?.e164Number ==
                    '+7123420'
                ) {
                    this.doCreateUserForNotDuplicateNumber();
                }
            }
        } else {
            this.doSuccessFormSubmit();
        }
    }

    /**
     * Method to submit the valid form
     */

    doSuccessFormSubmit() {
        const payload = this.registerForm.getRawValue() as CreateUserDto;
        this.otpMobileNumber =
            this.registerForm.controls['mobile'].value?.e164Number;
        this.spinner.show();
        this.registerService
            .getSendCustomerOtp(payload)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe({
                next: (response: RegisterResponseDto) => {
                    this.spinner.hide();
                    if (response.status == 'success') {
                        if (payload.mobile == '+230007123420') {
                            this.doCreateUserForNotDuplicateNumber();
                        } else {
                            this.modal.open(this.verifyOtpAccountModal, {
                                ariaLabelledBy: 'Verify OPT',
                                size: 'md',
                                modalDialogClass: 'verify-otp',
                            });
                        }

                        const customerId = response.dummyCustomerId;
                        this.registerForm.controls['customerId'].setValue(
                            customerId
                        );
                        this.commonService.setCache(
                            this,
                            'dummy_cust_id',
                            response.dummyCustomerId
                        );
                    } else {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.message
                        );
                    }
                },
                error: (error) => console.debug(error),
            });
    }

    onPhoneCountryChange(event: Country) {
        this.registerForm.controls['countryCode'].setValue(event.iso2);
        this.registerForm.controls['dialCode'].setValue(event.dialCode);
    }

    onChangeCountry(value) {
        if (value == 1) {
            if (this.registerForm.controls['country'].value === 'mu') {
                this.shouldDesiplayOtherCountriesSelect = false;
                this.selectedCountryCode = CountryISO.Mauritius;
                this.registerForm.controls['others'].setValue('');
            } else if (this.registerForm.controls['country'].value == 're') {
                this.shouldDesiplayOtherCountriesSelect = false;
                this.selectedCountryCode = CountryISO.Réunion;
                this.registerForm.controls['others'].setValue('');
            } else {
                this.shouldDesiplayOtherCountriesSelect = true;
                this.selectedCountryCode = CountryISO.Afghanistan;
                this.registerForm.controls['others'].setValue('Afghanistan');
            }
        } else {
            this.selectedCountryCode = this.getCountryISO(
                this.registerForm.controls['country'].value
            );
        }
    }

    getCountryISO(value: string): CountryISO {
        const keys = Object.keys(CountryISO).filter(
            (x) => CountryISO[x] === value
        );
        return keys.length > 0 ? CountryISO[keys[0]] : null;
    }

    getDevicePlatform() {
        return getCache('platform')
            ? getCache('platform').data
                ? getCache('platform').data
                : ''
            : '';
    }

    /**
     * Method to create user for default mobile number 007123420 input by user
     */

    doCreateUserForNotDuplicateNumber() {
        const payload = this.registerForm.getRawValue() as CreateUserDto;
        payload.country = this.getStringifiedCountry(payload.country);

        payload.mobile.e164Number =
            payload.mobile.e164Number == '+7123420'
                ? '+230007123420'
                : payload.mobile.e164Number;
        this.spinner.show();

        this.registerService
            .createUser(payload)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe({
                next: (response: RegisterResponseDto) =>
                    this.handleCreateUserResponse(response),
                error: (err) => console.error(err),
            });
    }

    setLang(data: RegisterResponseDto) {
        if (parseInt(data.groupId) == 8) {
            setCache('is_reunion', true);
            this.commonService.setCustomerGroupID(data.groupId);

            if (data.preferredLanguage) {
                this.translate.setDefaultLang(data.preferredLanguage);
                setCache('language', data.preferredLanguage);
            } else {
                this.translate.setDefaultLang(
                    getCache('language')
                        ? (getCache('language').data as string)
                        : 'en'
                );
                setCache(
                    'language',
                    getCache('language') ? getCache('language').data : 'en'
                );
            }

            setCache('storeID', 2);
            this.commonService.language = 'fr-reunion';
        } else if (parseInt(data.groupId) == 11) {
            setCache('is_reunion', false);

            this.commonService.setCustomerGroupID(parseInt(data.groupId));
            this.translate.setDefaultLang('en');
            setCache('storeID', 1);
            this.commonService.language = 'fr-reunion';
            setCache('language', 'en');
        } else {
            setCache('is_reunion', false);
            this.commonService.language = getCache('language')
                ? (getCache('language').data as string)
                : '';

            if (!this.commonService.language) {
                this.commonService.setCustomerGroupID(parseInt(data.groupId));
                this.translate.setDefaultLang('en');
                setCache('storeID', 1);
                this.commonService.language = 'en';
                setCache('language', 'en');
            } else {
                this.commonService.setCustomerGroupID(parseInt(data.groupId));
                if (this.commonService.language == 'en') {
                    this.translate.setDefaultLang('en');
                    setCache('storeID', 1);
                    setCache('language', 'en');
                    this.commonService.language = 'en';
                } else {
                    this.translate.setDefaultLang('fr');
                    setCache('storeID', 2);
                    setCache('language', 'fr');
                    this.commonService.language = 'fr';
                }
            }
        }
    }

    onOtpChange(value: string) {
        if (value.length == 4) {
            this.registerForm.controls['otp'].setValue(value);
            const payload = this.registerForm.getRawValue();
            payload.country = this.getStringifiedCountry(payload.country);

            this.spinner.show();

            this.registerService
                .createUser(payload)
                .pipe(finalize(() => this.spinner.hide()))
                .subscribe({
                    next: (response: RegisterResponseDto) =>
                        this.handleCreateUserResponse(response),
                });
        }
    }

    resendOtp() {
        this.spinner.show();
        this.registerService
            .resendOtp(this.registerForm.controls['email'].value)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe({
                next: (response: ResendOtpResponseDto) => {
                    if (response.status === 'success') {
                        this.otpMobileNumber =
                            this.registerForm.controls['email'].value;
                    } else {
                        this.eventService.emitEvent(
                            EventType.MODAL_ERROR,
                            response.message
                        );
                    }
                },
                error: (err) => {
                    console.debug(err);
                    this.eventService.emitEvent(
                        EventType.MODAL_ERROR,
                        this.localeObj.getAlertMessages().server_not_responding
                    );
                },
            });
    }

    toggleShowPassword() {
        this.showHidePass = !this.showHidePass;
        if (this.showHidePass) {
            this.passwordIconClass = 'fa-eye-slash';
            this.passwordInputType = 'text';
        } else {
            this.passwordIconClass = 'fa-eye';
            this.passwordInputType = 'password';
        }
    }

    private handleCreateUserResponse(response: RegisterResponseDto): void {
        if (response.jwtToken) {
            this.setLang(response);
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                response.message
            );
            this.tokenService.saveJwtToken(response.jwtToken);
            this.commonService.side_drawer_user_initial =
                response.firstName.split('')[0];
            this.commonService.side_drawer_user =
                response.firstName + ' ' + response.lastName;
            this.commonService.side_drawer_email = response.username
                ? response.username
                : response.email;
            // this.commonService.badges = response.availableBadges;
            setBadgeCount(response.availableBadges);
            // this.commonService.balancePoints =
            //   response.rewardData.balancePoints;
            setRewardPoints(response.rewardData.balancePoints);
            this.commonService.isLoggedIn = true;
            this.loginService.verifyNormalLogin(
                this.registerForm.getRawValue()['email'],
                btoa(this.registerForm.getRawValue()['password'])
            );
        } else {
            this.eventService.emitEvent(
                EventType.MODAL_ERROR,
                response.message
            );
        }
    }

    navigateToLogin() {
        this.eventService.emitEvent(EventType.LOGIN);
    }

    protected readonly CountryISO = CountryISO;

    private getStringifiedCountry(countryCode: string): string {
        if (countryCode === this.MAURITIUS.code) {
            return JSON.stringify({
                key: this.MAURITIUS.name,
                value: this.MAURITIUS.code,
            });
        }
        if (countryCode === this.REUNION.code) {
            return JSON.stringify({
                key: this.REUNION.name,
                value: this.REUNION.code,
            });
        }
        return JSON.stringify({
            key: this.OTHER_COUNTRY.name,
            value: this.OTHER_COUNTRY.code,
        });
    }
}
