import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { getApiUrlMofluid } from '../utilities/url.utils';
import {
    CreateUserDto,
    RegisterRawResponseDto,
    RegisterResponseDto,
    ResendOtpResponseDto,
    transform,
} from './model/register.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RegisterService {
    loginStatus!: number;

    constructor(
        private httpClient: HttpClient,
        private common: CommonService,
        public router: Router
    ) {}

    createUser(data: CreateUserDto): Observable<RegisterResponseDto> {
        let reqOtp = 1;
        let newUrl = getApiUrlMofluid();
        if (data.country === 'RU') {
            newUrl = getApiUrlMofluid().replace('/rest/V1/', '/rest/fr/V1/');
        }
        if (data.mobile.e164Number === '+230007123420') {
            reqOtp = 0;
        }
        const mobile = this.common.encode(data.mobile.e164Number);
        const password = this.common.encode(data.password);
        const params = new HttpParams()
            .set('store', this.common.getStoreID())
            .set('password', password)
            .set('service', 'createuser')
            .set('email', data.email)
            .set('firstname', data.firstName)
            .set('lastname', data.lastName)
            .set('dob', '')
            .set('phone', mobile)
            .set('otp_number', data.otp)
            .set('dummy_customer_id', data.customerId)
            .set('country', data.country)
            .set('is_otp_required', reqOtp)
            .set('new_version', '1')
            .set('is_real_login', '')
            .set('is_app_login', 0);
        return this.httpClient
            .get<RegisterRawResponseDto>(newUrl + 'createuser', {
                params: params,
                headers: this.common.getTokenHeaders(),
            })
            .pipe(
                map((response: RegisterRawResponseDto) => transform(response))
            );
    }

    getSendCustomerOtp(data: CreateUserDto): Observable<RegisterResponseDto> {
        const mobile = this.common.encode(
            JSON.stringify(
                data.mobile.e164Number === '+7123420'
                    ? '+230007123420'
                    : data.mobile.e164Number
            )
        );
        const params = new HttpParams()
            .set('store', this.common.getStoreID())
            .set('currency', data.countryCode)
            .set('service', 'sendCustomerOtp')
            .set('email', data.email)
            .set('mobile_number', mobile)
            .set('new_version', '1');
        return this.httpClient
            .get<RegisterRawResponseDto>(
                getApiUrlMofluid() + 'sendCustomerOtp',
                { params: params, headers: this.common.getTokenHeaders() }
            )
            .pipe(
                map((response: RegisterRawResponseDto) => transform(response))
            );
    }

    resendOtp(email: string): Observable<ResendOtpResponseDto> {
        const sendOtpToCustomer = getApiUrlMofluid() + 'sendCustomerOtp';
        const params = new HttpParams()
            .set('store', '1')
            .set('currency', 'MUR')
            .set('service', 'sendCustomerOtp')
            .set('email', email)
            .set('customer_group_id', this.common.getCustomerGroupID());
        return this.httpClient.get<ResendOtpResponseDto>(sendOtpToCustomer, {
            params: params,
            headers: this.common.getTokenJWTheaders(),
        });
    }
}
