<div class="main-div">
    <div class="card">
        <div class="card-body">
            <!-- Start Email Login Form -->
            <form [formGroup]="emailLoginForm" (ngSubmit)="onSubmitEmailLoginForm()"
                  [ngClass]="['email-login-form']"
                  *ngIf="showEmailForm">
                <div class="form-group">
                    <label for="email"
                           [ngClass]="emailLoginForm.value.email.length > 0 ? 'has-input' : ''">{{'Email Address' |translate}}</label>
                    <input type="text"
                           id="email"
                           #email
                           formControlName="email"
                           (focusin)="emailErrorClass = ''"
                           (focusout)="validateEmail()"
                           [ngClass]="['form-control', emailErrorClass, formErrorClass]"
                           [placeholder]="'Email Address' | translate"/>
                </div>
                <div class="form-group">
                    <label for="password"
                           [ngClass]="emailLoginForm.value.password.length > 0 ? 'has-input' : ''">{{'Password' | translate}}</label>
                    <input type="{{showPassword ? 'text' : 'password'}}"
                           id="password"
                           #password
                           formControlName="password"
                           (focusin)="passwordErrorClass = ''"
                           (focusout)="validatePassword()"
                           [ngClass]="['form-control', passwordErrorClass, formErrorClass]"
                           [placeholder]="'Password' | translate"/>
                    <div (click)="toggleShowPassword()"
                         [ngClass]="['fa', passwordClass, 'showandhidepass']"></div>
                </div>
                <div class="term-content form-group">
                    {{'By clicking an option below, I agree to the' | translate}}
                    <a href="https://marideal.zendesk.com/hc/en-us/articles/360013372919"
                       target="_blank">{{'Terms of Use' | translate}}</a>
                    {{'and have read the' |translate}}
                    <a href="https://marideal.zendesk.com/hc/en-us/articles/360013052960"
                       target="_blank">{{'Privacy Statement' | translate}}</a>
                </div>
                <div class="form-group">
                    <button class="btn ripleEffect" type="submit">{{'Sign in with MariDeal' | translate}}</button>
                </div>
            </form>
            <!-- End Email Login Form -->

            <!-- Start Mobile OTP Login Form -->
            <form [formGroup]="mobileLoginForm" (ngSubmit)="onSubmitMobileLoginForm()" class="mobile-login-form"
                  *ngIf="showMobileForm">
                <div class="form-group">
                    <label for="mobile">{{'Mobile Number' |translate}}</label>
                    <ngx-intl-tel-input [ngClass]="['mobile-input', mobileErrorClass]"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.Mauritius" [maxLength]="15"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        [customPlaceholder]="'Mobile Number' | translate" name="mobile"
                                        (focusout)="validateMobile()"
                                        (focusin)="mobileErrorClass = ''"
                                        formControlName="mobile">
                    </ngx-intl-tel-input>
                </div>
                <div class="term-content form-group">
                    {{'By clicking an option below, I agree to the' | translate}}
                    <a href="https://marideal.zendesk.com/hc/en-us/articles/360013372919"
                       target="_blank">{{'Terms of Use' | translate}}</a>
                    {{'and have read the' | translate}}
                    <a href="https://marideal.zendesk.com/hc/en-us/articles/360013052960"
                       target="_blank">{{'Privacy Statement' | translate}}</a>.
                </div>
                <div class="form-group">
                    <button class="btn ripleEffect" type="submit">{{'Sign in with MariDeal' | translate}}</button>
                </div>
            </form>
            <!-- End Mobile OTP Login Form -->

            <div class="forget-pass-new-acc">
                <a (click)="navigateToForgottenPassword()"
                   class="forgot-password">{{'Forgot Password?' | translate}}</a>
                <a (click)="navigateToRegister()"
                   class="create-account">{{'Create an account' | translate}}</a>
            </div>

            <div class="continue-with-box">
                <button class="btn btn-outline-secondary social-button ripleEffect" *ngIf="showEmailForm"
                        (click)="showEmailForm = false; showMobileForm = true">
                    <div class="social-display">
                        <img [src]="'assets/img/smartphone-icon.png'" class="social-img" alt="">
                        <div class="social-login">{{'Continue with Phone' | translate}}</div>
                    </div>
                </button>
                <button class="btn btn-outline-secondary social-button ripleEffect" *ngIf="showMobileForm"
                        (click)="showMobileForm = false; showEmailForm = true">
                    <div class="social-display">
                        <img [src]="'assets/img/smartemail-icon.png'" class="social-img" alt="">
                        <div class="social-login">{{'Continue with Email' | translate}}</div>
                    </div>
                </button>
            </div>

            <md-app-social-login></md-app-social-login>
        </div>
    </div>
</div>

<ng-template #verifyOtpTemplate let-modal>
    <div class="modal-header">
        <span class="popup-close-btn" (click)="modal.close('Save click')">×</span>
        <img [src]="'assets/img/OTP-Login.png'" alt="otp-login-image">
        <h4>{{'OTP Login' | translate}}</h4>
    </div>
    <div class="modal-body">
        <p>{{'Enter OTP code sent to' | translate}} {{fullMobileNumber}}</p>
        <!-- Start OTP Form -->
        <form [formGroup]="otpForm" (ngSubmit)="verifyOtp()" class="email-login-form">

            <div class="mb-6 text-center">
                <ng-otp-input id="partitioned"
                              (onInputChange)="onOtpChange($event)"
                              allowNumbersOnly="true"
                              [config]="{length:4}"></ng-otp-input>
            </div>

            <div class="form-group">
                <div>{{"Didn't receive OTP?" | translate}}</div>
                <a (click)="resendOtp()">{{('Resend' | translate) ? ('Resend' | translate) : 'Resend'}}</a>
            </div>
            <div class="form-group">
                <button class="ripleEffect" type="submit">{{'Verify' | translate}}</button>
            </div>
        </form>
        <!-- End OTP Form -->
    </div>
</ng-template>

<ng-template #otpResent let-modal>
    <div class="modal-body">
        {{'OTP resent successfully' | translate}}.
        <button
                type="button"
                class="btn btn-primary modalbtn"
                (click)="modal.close('Save click')"
                data-mdb-ripple-color="dark"
        >
            {{ "OK" | translate }}
        </button>
    </div>
</ng-template>