import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient: HttpClient, private commonService: CommonService) {}

  getCustomerVerificationInfo(customerId: any){
    return this.httpClient.get(this.commonService.api_url + "getCustomerVerificationInfo?store="+ this.commonService.getStoreID() +"&currency=MUR&service=getCustomerVerificationInfo&customerid=" + customerId + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  getPrivacySettings(){
    let customer_id: string = '';
    if(localStorage.getItem('marideal-pwa_user')){
      customer_id = JSON.parse(localStorage.getItem('marideal-pwa_user')).data.id;
    }
    return this.httpClient.get(this.commonService.api_url + "getPrivacySettings?store="+ this.commonService.getStoreID() +"&service=getPrivacySettings" + '&customerid=' + customer_id + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  verifyEmail(customerId: any){
    return this.httpClient.get(this.commonService.api_url + "sendCustomerOtp?store="+ this.commonService.getStoreID() +"&currency=MUR&service=sendCustomerOtp&customerid=" + customerId + "&send_otp_to=email" + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }
  verifyEmailOtp(customerId: any, otp: any){
    return this.httpClient.get(this.commonService.api_url + "verifyCustomerOtp?store="+ this.commonService.getStoreID() +"&currency=MUR&service=verifyCustomerOtp&customerid=" + customerId + "&send_otp_to=email&otp_number=" + otp + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  verifyMobile(customerId: any, encodedNewMob: any){
    return this.httpClient.get(this.commonService.api_url + "sendCustomerOtp?store="+ this.commonService.getStoreID() +"&currency=MUR&service=sendCustomerOtp&customerid=" + customerId + "&send_otp_to=mobile_login&is_customer_mobile_sent=1&mobile_number=" + encodedNewMob + "&is_mobile_verify=1" + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }
  verifyMobileOtp(customerId: any, otp: any, encodedNewMob: any){
    return this.httpClient.get(this.commonService.api_url + "verifyCustomerOtp?store="+ this.commonService.getStoreID() +"&currency=MUR&service=verifyCustomerOtp&customerid=" + customerId + "&send_otp_to=mobile_login&otp_number=" + otp + "&is_customer_mobile_sent=1&mobile_number=" + encodedNewMob + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  mofluidUpdateEmailMobile(customerId: any, encodedMobileObj: any, encodedEmailObj: any){
    return this.httpClient.get(this.commonService.api_url + "mofluidUpdateEmailMobile?store="+ this.commonService.getStoreID() +"&service=mofluidUpdateEmailMobile&customerid=" + customerId + "&billaddress=" + encodedMobileObj + "&profile=" + encodedEmailObj + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  changePassword(customerId: any, email: any, encodedOldPass: any, encodedNewPass: any){
    return this.httpClient.get(this.commonService.api_url + "changeprofilepassword?store="+ this.commonService.getStoreID() +"&service=changeprofilepassword&customerid=" + customerId + "&username=" + email + "&oldpassword=" + encodedOldPass + "&newpassword=" + encodedNewPass + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  downloadPrivacyCsv(customerId: any, encodedCurrentPass: any){
    return this.httpClient.get(this.commonService.api_url + "downloadPrivacyCSV?store="+ this.commonService.getStoreID() +"&service=downloadPrivacyCSV&customerid=" + customerId + "&password=" + encodedCurrentPass + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()});
  }

  deleteRequest(customerId: any, encodedCurrentPass: any){
    return this.httpClient.get(this.commonService.api_url + "deleteRequest?store=" + this.commonService.getStoreID() + "&service=deleteRequest&customerid=" + customerId + "&password=" + encodedCurrentPass + "&customer_group_id=" + this.commonService.getCustomerGroupID(), {headers: this.commonService.getTokenJWTheaders()})
  }
}
