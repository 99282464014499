<div class="main-div">
    <div class="fp-parent-div">
        <h1>{{'Forgot Password?' | translate}}</h1>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPassForm()">
            <div class="form-group">
                <p *ngIf="emailPara" class="emailPara">
                    {{'Enter the email address or phone number associated with your account and we\'ll send you a link to reset your password' | translate}}
                </p>
                <input type="email" name="" id="" formControlName="email"
                       (click)="emailPara = true; mobileOpacity = false"
                       [ngClass]="mobileOpacity ? 'low-opacity' : 'full-opacity'"
                       [placeholder]="'Email Address' | translate">
                <br>
                <h3>{{'or' | translate}}</h3>
                <ngx-intl-tel-input class="mobile-input" [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="false"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.Mauritius" [maxLength]="15"
                                    [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National"
                                    [ngClass]="mobileOpacity ? 'full-opacity' : 'low-opacity'"
                                    [customPlaceholder]="'Mobile Number' | translate" name="mobile"
                                    formControlName="mobile"
                                    (click)="clearForm(); emailPara = false; mobileOpacity = true">
                </ngx-intl-tel-input>
            </div>
            <div class="form-group button">
                <button class="ripleEffect" type="submit">{{'Send reset link' | translate}}</button>
            </div>
        </form>

        <ng-template #verifyOtpModal let-modal>
            <div class="modal-header">
                <span class="popup-close-btn" (click)="modal.close('Save click')">×</span>
                <img [src]="'assets/img/OTP-Login.png'" alt="otp-login-image">
                <h4>{{'Please Enter the OTP to verify your account' | translate}}</h4>
            </div>
            <div class="modal-body">
                <p>{{'OTP(one-time-password) has been sent to' | translate}} {{changeNumWithStars(forgotPasswordForm?.controls['mobile']?.value?.e164Number)}}</p>
                <!-- Start OTP Form -->
                <form class="mobile-verify-form">

                    <div class="mb-6 text-center">
                        <ng-otp-input id="partitioned"
                                      disabled="{{isOtpDisabled}}"
                                      (onInputChange)="mobileOtpInputChange($event)"
                                      allowNumbersOnly="true"
                                      [config]="{length:4}"></ng-otp-input>
                    </div>

                    <div class="form-group">
                        <div>{{"Didn't receive OTP?" | translate}}</div>
                        <a (click)="resendMobileOtp()">{{'Resend' | translate}}</a>
                    </div>

                    <div class="form-group">
                        <button (click)="verifyMobileOtp()" class="ripleEffect"
                                type="button">{{'Verify' | translate}}</button>
                    </div>
                </form>
                <!-- End OTP Form -->
            </div>
        </ng-template>
    </div>
</div>