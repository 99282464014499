<ng-template #login>
    <div class="modal-header">
        <md-logo color="blue" width="150" height="24" />
    </div>
    <div class="modal-body">
        <md-app-login></md-app-login>
    </div>
    <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
</ng-template>

<ng-template #register>
    <div class="modal-header">
        <md-logo color="blue" width="150" height="24" />
    </div>
    <div class="modal-body">
        <md-app-register></md-app-register>
    </div>
    <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
</ng-template>

<ng-template #forgottenPassword>
    <div class="modal-header">
        <md-logo color="blue" width="150" height="24" />
    </div>
    <div class="modal-body">
        <md-forget-password></md-forget-password>
    </div>
    <ngx-spinner id="ngx-spinner" type="ball-scale-multiple"></ngx-spinner>
</ng-template>

<ng-template #messageModal let-modal>
    <div class="modal-body">
        {{ messageModalText }}
        <button
            type="button"
            class="btn btn-primary modalbtn"
            (click)="modal.close('Save click'); executeModalCallback()"
            data-mdb-ripple-color="dark"
        >
            {{ "OK" | translate }}
        </button>
    </div>
</ng-template>
