import { Component, OnInit } from '@angular/core';
import { ChallengesService } from './challenges.service';
import { SwiperOptions } from 'swiper';
import { CommonService } from '../../../shared/common.service';
import { EventService, EventType } from '../../../utilities/event.service';
import { getUser } from '../../../utilities/user.utils';
import { User } from '../../../model/user.model';
import { ProfileSection } from '../../profile-wrapper.component';
import { screenSizes } from 'src/app/utilities/theme';

@Component({
    selector: 'app-challenges',
    templateUrl: './challenges.component.html',
    styleUrls: ['./challenges.component.scss'],
})
export class ChallengesComponent implements OnInit {
    isCustomerData: boolean = false;
    isBadgesData: boolean = false;
    loginStatus!: number;
    public userLogin: boolean = false;
    earnSpendPoints = false;
    // TODO: All of these need actual types
    customerPointsAndBalance: any;
    rewardPointBadgesData: any;
    availedBadgesArray: any = [];
    notAvailedBadgesArray: any = [];

    availableBadgeCount: number = 0;
    totalBadgeCount: number = 0;

    constructor(
        private challengesService: ChallengesService,
        private eventService: EventService,
        private common: CommonService
    ) {}

    ngOnInit(): void {
        this.checkLoginResponse();
    }

    public config: SwiperOptions = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        spaceBetween: 27,
        slidesPerView: 3,
    };

    checkLoginResponse() {
        const userdata: User = getUser();
        if (userdata) {
            this.userLogin = true;
            this.getCustomerDetails();
            this.getBadgesData();
        } else {
            this.eventService.emitEvent(EventType.LOGIN);
        }
    }

    getCustomerDetails() {
        this.isCustomerData = true;
        this.challengesService.getCustumerPointsAndBalance().subscribe(
            (response: any) => {
                this.isCustomerData = false;
                this.customerPointsAndBalance = response;
            },
            (error) => {
                this.isCustomerData = false;
            }
        );
    }

    getBadgesData() {
        this.isBadgesData = true;
        this.challengesService.getRewardPointBadge().subscribe(
            (response: any) => {
                this.rewardPointBadgesData = response;
                for (let badges_in_groups of this.rewardPointBadgesData
                    ?.badges_info) {
                    for (let all_badges of badges_in_groups?.data) {
                        if (all_badges.is_availed === true) {
                            this.availedBadgesArray.push(all_badges);
                            this.isBadgesData = false;
                        }
                        if (all_badges.is_availed === false) {
                            this.notAvailedBadgesArray.push(all_badges);
                            this.isBadgesData = false;
                        }
                    }
                }

                this.availableBadgeCount = this.availedBadgesArray.length;
                this.totalBadgeCount =
                    this.availableBadgeCount +
                    this.notAvailedBadgesArray.length;
            },
            (error) => {
                this.isBadgesData = false;
            }
        );
    }

    showHideEarnSpendPointsAns() {
        this.earnSpendPoints = !this.earnSpendPoints;
    }

    protected readonly ProfileSection = ProfileSection;
}
