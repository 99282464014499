import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from '@capgo/ngx-intl-tel-input';
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService, EventType } from "../utilities/event.service";
import { getUser } from "../utilities/user.utils";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'md-app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

    emailLoginForm!: UntypedFormGroup;
    mobileLoginForm!: UntypedFormGroup;
    otpForm!: UntypedFormGroup;

    showEmailForm = true;
    showMobileForm = false;
    mobileErrorClass = '';

    showPassword = false;
    fullMobileNumber!: number;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.Mauritius
    ];
    customerId = '';
    otpInput: string;

    emailErrorClass = '';
    passwordErrorClass = '';
    formErrorClass = '';

    passwordClass = 'fa-eye';

    @ViewChild('verifyOtpTemplate') verifyOtpModal: TemplateRef<never>;

    @ViewChild('mobileAfterApiCallError') mobileAfterApiCallErrorModal: TemplateRef<never>;
    @ViewChild('otpResent') otpResentModal: TemplateRef<never>;

    @ViewChild('email') emailInput: ElementRef;
    @ViewChild('password') passwordInput: ElementRef;

    constructor(private loginService: LoginService,
                private elRef: ElementRef,
                private spinner: NgxSpinnerService,
                private translate: TranslateService,
                private modalService: NgbModal,
                private eventService: EventService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.checkLoginResponse();
        this.subscribeForFailedLogin();

        this.emailLoginForm = new UntypedFormGroup({
            'email': new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
            'password': new UntypedFormControl('', Validators.compose([Validators.required]))
        });

        this.mobileLoginForm = new UntypedFormGroup({
            'mobile': new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
        });

        this.otpForm = new UntypedFormGroup({
            'otp': new UntypedFormControl('', [Validators.maxLength(4)])
        });

    }

    validateEmail(shouldFocus = false): boolean {
        if (this.emailLoginForm.controls["email"].invalid) {
            if (shouldFocus) {
                this.emailInput.nativeElement.focus();
            }
            this.emailErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    validatePassword(shouldFocus = false): boolean {
        if (this.emailLoginForm.controls["password"].invalid) {
            if (shouldFocus) {
                this.passwordInput.nativeElement.focus();
            }
            this.passwordErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    onSubmitEmailLoginForm() {
        if (this.emailLoginForm.invalid) {
            let shouldFocus = true;
            shouldFocus = this.validateEmail(shouldFocus);
            this.validatePassword(shouldFocus);
            return;
        }
        this.emailErrorClass = '';
        this.passwordErrorClass = '';
        this.formErrorClass = '';
        const email = this.emailLoginForm.value.email;
        const pass = btoa(this.emailLoginForm.value.password)
        this.loginService.verifyNormalLogin(email, pass);
    }

    checkLoginResponse() {
        const userdata = getUser();
        if (userdata) {
            if (userdata.isSuccess) {
                this.router.navigate(['/']);
            } else {
                if (userdata.username) {
                    this.openIncorrectUserPassErrorPopup(this.translate.instant('The username or password you entered is incorrect'));
                } else if (userdata.otp) {
                    this.openIncorrectUserPassErrorPopup(this.translate.instant('Please enter correct OTP'));
                    this.otpForm.controls["otp"].patchValue('');
                }
            }
        }
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
        this.passwordClass = this.showPassword ? 'fa-eye-slash' : 'fa-eye';
    }

    validateMobile(shouldFocus = false): boolean {
        if (this.mobileLoginForm.controls["mobile"].invalid || this.mobileLoginForm.controls['mobile'].value?.number.length < 6) {
            if (shouldFocus) {
                document.getElementById('phone').focus();
            }
            this.mobileErrorClass = 'md-input-error md-error-shake';
            return false;
        }
        return true;
    }

    onSubmitMobileLoginForm() {
        if (this.validateMobile(true)) {
            this.spinner.show();
            this.fullMobileNumber = this.mobileLoginForm.controls['mobile'].value.e164Number;
            this.loginService.sendOtp(this.fullMobileNumber).subscribe((response) => {
                this.spinner.hide();
                const status = response['status'];
                if (status === 'error') {
                    this.eventService.emitEvent(EventType.MODAL_ERROR, response['message'])
                }
                if (status == 'success') {
                    this.customerId = response['customer_id'];
                    this.modalService.open(this.verifyOtpModal, {
                        ariaLabelledBy: 'Verify OPT',
                        size: 'md',
                        modalDialogClass: 'verify-otp'
                    });
                }
            });
        }
    }

    verifyOtp() {
        this.loginService.verifyOtpLogin(this.customerId, this.fullMobileNumber, this.otpInput);
    }

    resendOtp() {
        this.onSubmitMobileLoginForm();
        this.eventService.emitEvent(EventType.MODAL_INFO, this.translate.instant('OTP resent' +
          ' successfully'));
    }

    private openIncorrectUserPassErrorPopup(text: string): void {
        this.eventService.emitEvent(EventType.MODAL_ERROR, text);
    }

    onOtpChange($event: string) {
        this.otpInput = $event;
    }

    navigateToRegister() {
        this.eventService.emitEvent(EventType.REGISTER);
    }

    navigateToForgottenPassword() {
        this.eventService.emitEvent(EventType.FORGOTTEN_PASSWORD);
    }

    private subscribeForFailedLogin(): void {
        this.eventService.onEvent(EventType.LOGIN_FAILED, () => {
            this.formErrorClass = 'md-error-shake';
        });
    }
}
